<template>
  <div class="warning-box">
    <div class="tabLst-title"><span class="tabLst-title-span" @click="addwaring"
                                    v-if="this.$getPermission('/pc/conf/save')">+添加报警规则</span></div>
    <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="1100px" height="400px"
    >
      <warningDialog ref="child" :changeList="changeList"></warningDialog>
    </el-dialog>
    <el-table
        :data="waringList"
        border
        style="width: 100%"
        :row-class-name="tableRowClassName">
      <el-table-column
          prop="gname"
          label="大棚"
      >
      </el-table-column>
      <el-table-column
          prop="dname"
          label="环境参数"
      >
      </el-table-column>
      <el-table-column
          prop="cond"
          label="条件">
      </el-table-column>
      <el-table-column
          prop="val1"
          label="值">
      </el-table-column>
      <el-table-column
          prop="createTime"
          label="添加时间">
      </el-table-column>
      <el-table-column
          prop="date"
          label="操作">
        <template slot-scope="scope">
          <el-button
              size="mini"
              @click="handleEdit(scope.$index, scope.row)">编辑
          </el-button>
          <el-popconfirm style="margin-left: 10px"
                         title="确定删除吗？"
                         @confirm="handleDelete(scope.$index, scope.row)"
          >
            <el-button
                size="mini"
                type="danger"
                slot="reference"
            >删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>


    </el-table>
  </div>
</template>

<script>
import warningDialog from '@/components/warningDialog.vue'

export default {
  components: {
    warningDialog
  },
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      value1: '',
      waringList: [],
      changeList: []
    }
  },
  mounted() {

    this.$api.post('conf/list', {}, result => {
      console.log(result)
      this.waringList = result.data.records
    })
  },
  methods: {
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    handleEdit(index, row) {  // 修改按钮
      console.log(index, row.id);
      if (this.$getPermission('/pc/conf/save')) {
        this.dialogVisible = true
        console.log(index, this.waringList, '测试数据')
        let idx = this.waringList.findIndex((item) => item.id === row.id)
        console.log(idx, 'idx')
        this.changeList = this.waringList[idx]
        console.log(this.changeList, 'this.changeList')
        setTimeout(() => {
          this.$refs.child.changeButton();
        }, 10)
      } else {
        this.$message({
          showClose: true,
          message: '暂无权限',
          type: 'warning'
        });
      }
    },
    addwaring() {  // 修改按钮
      this.dialogVisible = true
      setTimeout(() => {
        this.$refs.child.addButton();
      }, 10)

    },
    handleDelete(index, row) {  //删除按钮
      console.log(index, row);
      if (this.$getPermission('/pc/conf/delById')) {
      this.$api.post('conf/delById', {id: row.id}, res => {
        console.log(res, '删除', this.tableData)
        if (res.code === 0) {
          this.$message({
            message: '删除成功',
            showClose: true,
            duration: 1500,
            type: 'success',
            onClose() {
              location.reload();
            }
          });
        }
      })
      }else{
        this.$message({
          showClose: true,
          message: '暂无权限',
          type: 'warning'
        });
      }

    },

  }
}
</script>

<style scoped>

.tabLst-title {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #1A99F2;
  cursor: pointer;
  margin-bottom: 10px;
}

.warning-box {
  background: #FFFFFF;
  margin-top: 20px;
  padding: 30px 36px;
  min-height: 85vh;
}
</style>
