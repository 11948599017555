<template>
  <div style="height: 280px">
    <div class="dialog-box-ttile">{{ buttonText }}报警规则</div>
    <el-col :span="3" class="riht-title-name" style="text-align: center">如果</el-col>
    <el-col :span="4">
      <el-select v-model="selectgreenHouse" placeholder="选择大棚" style="width: 160px;">
        <el-option
            v-for="item in greenHouseList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="4">
      <el-select v-model="environmental" placeholder="选择环境参数" style="width: 160px;">
        <el-option
            v-for="item in environmentalList"
            :key="item.value"
            :label="item.name"
            :value="item.value">
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="4">
      <el-select v-model="selectcondition" placeholder="选择条件" style="width: 160px;">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.name"
            :value="item.value">
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="3">
      <el-input v-model="valueInput" placeholder="填写阈值"></el-input>
      <!--      <span style="float: bottom">℃</span>-->
    </el-col>
    <el-col :span="4">
      <div class="riht-title-name">则报警</div>
    </el-col>
    <el-col style="text-align: right;margin-top: 20px;margin-bottom: 40px">报警方式为系统内部消息提示</el-col>

    <div style="text-align: center;">
      <el-button class="dialog-button" @click="update">{{ buttonText }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "warningDialog",
  data() {
    return {
      options: [{'name': '最大值', 'value': 'max'}, {'name': '最小值', 'value': 'min'}],
      greenHouseList: [],
      valueInput: '',
      selectgreenHouse: '',
      environmentalList: [],
      environmental: '',
      selectcondition: '',
      buttonText:'添加',
      id:''
    }
  },
  props: {
    changeList: {}
  },
  mounted() {
    this.$api.post('gHouse/getByBid', {}, res => {
      console.log(res)
      this.greenHouseList = res.data
    })
    this.$api.post('dict/types', {}, resa => {
      console.log(resa)
      this.environmentalList = resa.data
    })

  },
  methods: {
    changeButton(){
      console.log(this.changeList,'查看数据')
      this.selectgreenHouse = this.changeList.gId
      this.environmental = this.changeList.type
      this.selectcondition = this.changeList.max ? 'max' : 'min'
      this.valueInput = this.changeList.val1
      this.id = this.changeList.id
      this.buttonText = '修改'
    },
    addButton(){
      this.selectgreenHouse = ''
      this.environmental = ''
      this.selectcondition = ''
      this.valueInput = ''
      this.buttonText = '添加'
    },
    update() {
      if (this.buttonText === '修改') {  //修改
        let params = {
          id:this.id,
          gId: this.selectgreenHouse,
          type: this.environmental,
          val1: this.valueInput,
          valType: this.selectcondition
        }
        console.log(params)
        this.$api.post('conf/save', params, res => {
          console.log(res, 'wwww')
          if (res.code === 0) {
            console.log('修改')
            location.reload();
          }
        })
      } else {   //添加
        let params = {
          gId: this.selectgreenHouse,
          type: this.environmental,
          val1: this.valueInput,
          valType: this.selectcondition
        }
        console.log(params)
        this.$api.post('conf/save', params, res => {
          console.log(res, 'wwww')
          if (res.code === 0) {
            location.reload()
          } else {
            this.$message.error(res.msg);
          }
        })
      }

    }
  },
  // watch: {
  //   changeList: {
  //     handler: function (val) {
  //       console.log(val,'搜索   ============================')
  //       if (this.changeList.id) {
  //         this.selectgreenHouse = val.gname
  //         this.environmental = val.dname
  //         this.selectcondition = val.max ? 'max' : 'min'
  //         this.valueInput = val.val1
  //         this.buttonText = '修改'
  //       } else {
  //         this.selectgreenHouse = ''
  //         this.environmental = ''
  //         this.selectcondition = ''
  //         this.valueInput = ''
  //         this.buttonText = '添加'
  //       }
  //     }
  //   }
  // }
}
</script>

<style scoped>
.dialog-box-ttile {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  text-align: center;
  margin-bottom: 50px;
}

.riht-title-name {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 35px;
  margin-left: 20px;
}

.dialog-button {
  width: 300px;
  height: 60px;
  background: #009400;
  border-radius: 30px;

  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FEFEFE;
}
</style>
